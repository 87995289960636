.loading-spinner-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 10px 0;

	&.overlay {
		padding: 20px 0;
		background: rgba(255, 255, 255, 0.9);
	}

	&.cover {
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(255, 255, 255, 0.9);
	}

	.loading-spinner {
		border: 5px solid transparent;
		border-radius: 50%;
		border-top: 5px solid #1D9C41;
		border-left: 5px solid #1D9C41;
		border-right: 5px solid #1D9C41;
		width: 40px;
		height: 40px;
		-webkit-animation: spin 1.5s linear infinite;
		animation: spin 1.5s linear infinite;
	}

	@-webkit-keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		0% {
			-webkit-transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
}
